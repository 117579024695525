
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import AvailabilityIndicator from '@/components/ServiceCard/AvailabilityIndicator/AvailabilityIndicator.vue'
import StreetDistanceIndicator from '@/components/ServiceCard/StreetDistanceIndicator/StreetDistanceIndicator.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import PartouCarousel from '@/components/PartouComponents/PartouCarousel.vue'
import PartouLoader from '@/components/PartouComponents/PartouLoader.vue'
import ChildBenefitCalculator from '@/components/ChildBenefitCalculator/ChildBenefitCalculator.vue'
import { Feature, Proposition } from '@/models'
import PropositionSubscription from '@/models/PropositionSubscription'
import { ROUTES } from '@/router/routes'
import FakeLink from '@/components/Generic/FakeLink.vue'
import Availability from '@/models/enums/Availability'

@Component({
  components: { PartouButton, PartouCard, PartouCarousel, PartouLoader, AvailabilityIndicator, ChildBenefitCalculator, StreetDistanceIndicator, FakeLink }
})
export default class ServiceCard extends Vue {
  @Ref('moreInfoButton')
  moreInfoButton! : PartouButton

  @Prop({ required: true })
  value! : Proposition

  @Prop({ required: false })
  defaultFeatures?: Feature[]

  @Prop({ required: false, default: false })
  isLoadingPrice?: boolean

  @Prop({ required: false, default: false })
  hideAvailabilityIndicator?: boolean

  readonly locationDetailRoute = ROUTES.locationDetail

  get showAvailabilityIndicator () : boolean {
    return this.value.availability !== undefined && !this.hideAvailabilityIndicator
  }

  get showPrice () : boolean {
    return (!!this.value?.propositionSubscriptions || !!this.isLoadingPrice) && this.value?.availability !== Availability.NotBookable
  }

  features? : Feature[] = []

  created () : void {
    this.renderFeatures()
  }

  focus () : void {
    this.moreInfoButton.focus()
  }

  onServiceCardClicked () : void {
    if (!this.$vuetify.breakpoint.smAndDown) {
      this.onStreetIndicatorClicked()
    }
  }

  onStreetIndicatorClicked () : void {
    this.$emit('onStreetIndicatorClicked', this.value.id)
  }

  getDefaultSubscription () : PropositionSubscription | undefined {
    if (this.value && this.value.propositionSubscriptions) {
      const subscriptions = this.value.propositionSubscriptions
      if (subscriptions.length) {
        return subscriptions[0]
      }
    }

    return undefined
  }

  @Watch('defaultFeatures')
  renderFeatures () : void {
    const nrFeaturesToShow = 3
    const currentFeatures = this.value.serviceFeatures?.map(x => x.feature).slice(0, nrFeaturesToShow) ?? []
    if (currentFeatures.length < nrFeaturesToShow && this.defaultFeatures) {
      const defaultFeatures = [...this.defaultFeatures]
      while (currentFeatures.length < 3 && defaultFeatures.length > 0) {
        const randomFeatureIndex = Math.floor(Math.random() * ((defaultFeatures.length - 1) + 1))
        const randomFeature = defaultFeatures.splice(randomFeatureIndex, 1)[0]
        if (!currentFeatures.find(x => x.description === randomFeature.description)) {
          currentFeatures.push(randomFeature)
        }
      }
    }
    Vue.set(this, 'features', currentFeatures)
  }
}
